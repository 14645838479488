@font-face {
  font-family: 'SF Pro';
  font-weight: 300;
  src: url('./fonts/SF-Pro-Text-Light.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 400;
  src: url('./fonts/SF-Pro-Text-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 500;
  src: url('./fonts/SF-Pro-Text-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 600;
  src: url('./fonts/SF-Pro-Text-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro';
  font-weight: 700;
  src: url('./fonts/SF-Pro-Text-Bold.otf') format('opentype');
}

*,
html,
body {
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: SF Pro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
